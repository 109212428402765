
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import DocumentsUploadForm from "@/components/forms/DocumentsUploadForm.vue";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";

@Options({
  components: {
    SectionTitle,
    DocumentsUploadForm,
    LeftStepBar,
  },
  beforeMount() {
    this.$store.commit("onboarding/checkProfile");
  },
  mounted() {
    this.$store.dispatch("onboarding/getProfile");
  },
})
export default class DocumentsUpload extends Vue {}
